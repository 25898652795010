<template>
  <BaseDocumentationCard
    v-model="documentation"
    :resource="project"
    :delete-end-point="deleteEndPoint"
    :update-end-point="updateEndPoint"
    :observations="project ? project.observations : ''"
  />
</template>

<script>
import BaseDocumentationCard from '@/components/ui/card/BaseDocumentationCard.vue'

export default {
  name: 'ProjectDocumentationCard',
  components: { BaseDocumentationCard },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  computed: {
    documentation: {
      get() {
        if (!this.project) {
          return []
        }

        return this.project.documentation
      },
      set(value) {
        this.project.documentation = value
      },
    },
    deleteEndPoint() {
      return this.project ? `/projects/delete_doc/${this.project.id}` : null
    },
    updateEndPoint() {
      return this.project ? `/projects/${this.project.id}` : null
    },
  },
}
</script>
