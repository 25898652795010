<template>
  <div>
    <ProjectProfileCard :project="project" />
    <ProjectDocumentationCard :project="project" />
    <b-tabs
      pills
      class="pb-2"
    >
      <b-tab
        title="Ofertas"
        :disabled="!offerTabVisible"
      >
        <BaseCard
          v-if="offerTabVisible"
          :footer-visible="false"
          actions-visible
        >
          <template #title>
            <span class="h3 font-weight-bold mb-0">Ofertas</span>
          </template>
          <template #actions>
            <b-link :to="{ name: 'createOffer', query: { 'project-id': $route.params.id } }">
              <b-button
                variant="primary"
                class="text-nowrap rounded-sm px-2"
              >
                + Añadir oferta
              </b-button>
            </b-link>
          </template>
          <OffersTable
            :project-id="projectId"
          />
        </BaseCard>
      </b-tab>
      <b-tab
        title="Jobs"
        :disabled="!jobTabVisible"
      >
        <BaseCard
          v-if="jobTabVisible"
          :footer-visible="false"
          actions-visible
        >
          <template #title>
            <span class="h3 font-weight-bold mb-0">Jobs</span>
          </template>
          <template #actions>
            <b-link :to="{ name: 'createJob', query: { 'project-id': $route.params.id } }">
              <b-button
                variant="primary"
                class="text-nowrap rounded-sm px-2"
              >
                + Añadir job
              </b-button>
            </b-link>
          </template>
          <JobsTable
            :project-id="projectId"
            :excluded-filters="['project_id']"
          />
        </BaseCard>
      </b-tab>
    </b-tabs>
    <BaseCommentCard :filters="commentsFilters" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApiRestService from '@/api/base-api'
import OffersTable from '@/components/offers/table/OffersTable.vue'
import JobsTable from '@/components/jobs/table/JobsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import ProjectProfileCard from '@/components/projects/card/ProjectProfileCard.vue'
import ProjectDocumentationCard from '@/components/projects/card/ProjectDocumentationCard.vue'
import BaseCommentCard from '@/components/ui/card/BaseCommentCard.vue'

export default {
  components: {
    BaseCommentCard,
    ProjectDocumentationCard,
    ProjectProfileCard,
    BaseCard,
    JobsTable,
    OffersTable,
  },
  data() {
    return {
      project: null,
      projectId: this.$route.params.id.toString(),
      commentsFilters: { project_id: this.$route.params.id },
    }
  },
  computed: {
    ...mapGetters('auth', {
      canAccessResource: 'canAccessResource',
    }),
    jobTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_JOBS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
    offerTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_OFFERS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
  },
  mounted() {
    this.loadProject()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async loadProject() {
      this.setLoading(true)
      try {
        const response = await ApiRestService.get(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS)
        this.project = response.data
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
